Array.prototype.uniq = function() {
  var i, key, output, ref, results, value;
  output = {};
  for (key = i = 0, ref = this.length; 0 <= ref ? i < ref : i > ref; key = 0 <= ref ? ++i : --i) {
    output[this[key]] = this[key];
  }
  results = [];
  for (key in output) {
    value = output[key];
    results.push(value);
  }
  return results;
};

Array.prototype.flatten = function() {
  return this.reduce(function(arr, obj) {
    return arr.concat(obj);
  }, []);
};

Array.prototype.compact = function() {
  return this.reduce(function(arr, obj) {
    if (typeof obj !== 'undefined') {
      arr.push(obj);
    }
    return arr;
  }, []);
};

Array.prototype.toMap = function(){
  return this.reduce(function(map, obj) {
    map[obj.id]=obj;
    return map;
  }, {});
}

Array.prototype.minusArray = function(arr) {
  var i, len, new_arr, orig_el;
  new_arr = [];
  for (i = 0, len = this.length; i < len; i++) {
    orig_el = this[i];
    if (arr.indexOf(orig_el) === -1) {
      new_arr.push(orig_el);
    }
  }
  return new_arr;
};

Array.prototype.last = function() {
  return this[this.length - 1];
};

Array.prototype.findById = function(id) {
  if (!id) {
    return {};
  }

  for (let i = 0; i < this.length; i++) {
    const el = this[i];
    if (el.id === parseInt(id)) {
      return el;
    }
  }
  return {};
};

Array.prototype.ids = function() {
  return this.map(e=> e.id);
};

Array.prototype.$remove = function(e) {
  const index = this.indexOf(e);
  return this.splice(index, 1);
};
Array.prototype.remove = function(e) {
  console.trace('do not use Array.remove, but Array.$remove')
  const index = this.indexOf(e);
  return this.splice(index, 1);
};

Array.prototype.any = function(el) {
  var e, i, j, len, len1;
  if (typeof el === "function") {
    for (i = 0, len = this.length; i < len; i++) {
      e = this[i];
      if (el.call(this, e)) {
        return true;
      }
    }
    return false;
  } else {
    for (j = 0, len1 = this.length; j < len1; j++) {
      e = this[j];
      if (e === el) {
        return true;
      }
    }
    return false;
  }
};
Array.prototype.groupBy = function(f){
  this.reduce((a,b)=>((a[f(b)]||=[]).push(b),a),{});
}

Array.prototype.chunked = function(size) {
  if (size == null) size = 100;
  const chunks = [];
  const items = this.slice();
  while (items.length) {
    chunks.push(items.splice(0, size));
  }
  return chunks;
};

Array.prototype.sortBy = function(attr){
  return this.sort((a,b) => {
    return JSON.stringify(a[attr]).localeCompare(JSON.stringify(b[attr]), 'en', { sensitivity: 'base' })
  })
}
